<template>
  <div>
    <button type="primary" @click="() => handleShow('BTCUSDT')">
      {{ show ? 'Destory' : 'Render' }} children
    </button>
    <br />
    <br />
    <div v-if="show" vertical>
      <div align="center">
        <h3>&emsp;&emsp;Cached:</h3>
        <CacheComponent :symbol="'BTCUSDT'"/>
      </div>
      <div align="center">
        <h3>&nbsp;Not cached:</h3>
        <NonCacheComponent :symbol="'ETHUSDT'"/>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, defineComponent, h } from 'vue'
import { useRequest } from 'vue-request'
import { Ticker } from '@/api/market.js'

// const request = async (fetcher, opts) => {
//   return new Promise((resolve, reject) => {
//     var data = useRequest(fetcher, {
//       defaultParams: opts,
//       onSuccess: res => {
//         resolve(res, data)
//         if (res) {
//           this.$toast.clear()
//         }
//       },
//       onError: error => reject(error)
//     })
//   })
// }

const generateComponent = (cached = false) =>
  defineComponent({
    props: ['symbol'],
    setup (props) {
      const defaultParams = [props.symbol]
      const opts = cached ? { cacheKey: 'date' } : { }
      const { data, loading } = useRequest(Ticker, {
        // manual: true,
        defaultParams,
        opts,
        // cacheKey: params => {
        //   if (params && params[0]) {
        //     return `date-${params[0]}`
        //   }
        //   console.log(params)
        //   return ''
        // },
        onSuccess: res => {
          if (res) {
            this.$toast.clear()
          }
        }
      })
      return () => {
        if (data.value === undefined && loading.value) {
          return h('span', { size: 'small' })
        }
        return h('span', { time: data.value })
      }
    }
  })
const CacheComponent = generateComponent(true)
const NonCacheComponent = generateComponent(true)

export default {
  components: {
    CacheComponent, NonCacheComponent
  },
  setup () {
    const show = ref(false)
    const handleShow = () => {
      show.value = !show.value
    }
    return {
      show,
      handleShow
    }
  }
}

</script>
